import request from '@/utils/request'


// 查询模板属性信息列表
export function listSampleDataAttr(query) {
  return request({
    url: '/gather/sample/data/attr/list',
    method: 'get',
    params: query
  })
}

// 查询模板属性信息分页
export function pageSampleDataAttr(query) {
  return request({
    url: '/gather/sample/data/attr/page',
    method: 'get',
    params: query
  })
}

// 查询模板属性信息详细
export function getSampleDataAttr(data) {
  return request({
    url: '/gather/sample/data/attr/detail',
    method: 'get',
    params: data
  })
}

// 新增模板属性信息
export function addSampleDataAttr(data) {
  return request({
    url: '/gather/sample/data/attr/add',
    method: 'post',
    data: data
  })
}

// 修改模板属性信息
export function updateSampleDataAttr(data) {
  return request({
    url: '/gather/sample/data/attr/edit',
    method: 'post',
    data: data
  })
}

// 删除模板属性信息
export function delSampleDataAttr(data) {
  return request({
    url: '/gather/sample/data/attr/delete',
    method: 'post',
    data: data
  })
}
