<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="sampleId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('模板id')}}<template slot="title">{{$t('模板id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sampleId"  :placeholder="$t('通用.输入.请输入')+$t('模板id')" />
      </a-form-model-item>
      <a-form-model-item prop="regAddr" >
        <span slot="label" >
          <a-tooltip>
            {{$t('协议文档中的地址')}}<template slot="title">{{$t('协议文档中的地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.regAddr"  :placeholder="$t('通用.输入.请输入')+$t('协议文档中的地址')" />
      </a-form-model-item>
      <a-form-model-item prop="regAddrBit" >
        <span slot="label" >
          <a-tooltip>
            {{$t('位数')}}<template slot="title">{{$t('位数')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.regAddrBit"  :placeholder="$t('通用.输入.请输入')+$t('位数')" />
      </a-form-model-item>
      <a-form-model-item prop="attributeName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('属性名称')}}<template slot="title">{{$t('属性名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.attributeName" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('属性名称')" />
      </a-form-model-item>
      <a-form-model-item prop="attributeIdentifier" >
        <span slot="label" >
          <a-tooltip>
            {{$t('属性标识符同一模板下需要唯一')}}<template slot="title">{{$t('属性标识符同一模板下需要唯一')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.attributeIdentifier" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('属性标识符同一模板下需要唯一')" />
      </a-form-model-item>
      <a-form-model-item prop="attributeType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('属性类型(0.设备上报 1.云端下发 2.设备云端共享 3.云端私有)')}}<template slot="title">{{$t('属性类型(0.设备上报 1.云端下发 2.设备云端共享 3.云端私有)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="dataType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('属性类型Number(数值) Switch(开关量) Text(文本) Enum(枚举) Object(键值对) List(列表) MapPoint(地理坐标) MapCircle(地理圆形围栏) MapPolygon(地理多边形围栏)')}}<template slot="title">{{$t('属性类型Number(数值) Switch(开关量) Text(文本) Enum(枚举) Object(键值对) List(列表) MapPoint(地理坐标) MapCircle(地理圆形围栏) MapPolygon(地理多边形围栏)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="dataTemplate" >
        <span slot="label" >
          <a-tooltip>
            {{$t('根据属性类型配置解析模板Number(单位,默认值,精度,最大值,最小值) Switch(开关类型,开名称,关名称) Text(默认值) Enum(枚举项:值-描述) Object List MapPoint MapCircle MapPolygon')}}<template slot="title">{{$t('根据属性类型配置解析模板Number(单位,默认值,精度,最大值,最小值) Switch(开关类型,开名称,关名称) Text(默认值) Enum(枚举项:值-描述) Object List MapPoint MapCircle MapPolygon')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('属性备注')}}<template slot="title">{{$t('属性备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="sort" >
        <span slot="label" >
          <a-tooltip>
            {{$t('排序')}}<template slot="title">{{$t('排序')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sort"  :placeholder="$t('通用.输入.请输入')+$t('排序')" />
      </a-form-model-item>
      <a-form-model-item prop="isDelete" >
        <span slot="label" >
          <a-tooltip>
            {{$t('删除标识(0未删除 1已删除)')}}<template slot="title">{{$t('删除标识(0未删除 1已删除)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isDelete"  :placeholder="$t('通用.输入.请输入')+$t('删除标识(0未删除 1已删除)')" />
      </a-form-model-item>
      <a-form-model-item prop="projectId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('项目id')}}<template slot="title">{{$t('项目id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.projectId"  :placeholder="$t('通用.输入.请输入')+$t('项目id')" />
      </a-form-model-item>
      <a-form-model-item prop="attributeUnit" >
        <span slot="label" >
          <a-tooltip>
            {{$t('变量单位')}}<template slot="title">{{$t('变量单位')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.attributeUnit" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('变量单位')" />
      </a-form-model-item>
      <a-form-model-item prop="icon" >
        <span slot="label" >
          <a-tooltip>
            {{$t('变量图标')}}<template slot="title">{{$t('变量图标')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.icon" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('变量图标')" />
      </a-form-model-item>
      <a-form-model-item prop="threshold" >
        <span slot="label" >
          <a-tooltip>
            {{$t('阈值范围')}}<template slot="title">{{$t('阈值范围')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="store" >
        <span slot="label" >
          <a-tooltip>
            {{$t('存储方式')}}<template slot="title">{{$t('存储方式')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.store"  :placeholder="$t('通用.输入.请输入')+$t('存储方式')" />
      </a-form-model-item>
      <a-form-model-item prop="storeTimer" >
        <span slot="label" >
          <a-tooltip>
            {{$t('定时存储周期分钟')}}<template slot="title">{{$t('定时存储周期分钟')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.storeTimer"  :placeholder="$t('通用.输入.请输入')+$t('定时存储周期分钟')" />
      </a-form-model-item>
      <a-form-model-item prop="storeRange" >
        <span slot="label" >
          <a-tooltip>
            {{$t('变化幅度')}}<template slot="title">{{$t('变化幅度')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.storeRange"  :placeholder="$t('通用.输入.请输入')+$t('变化幅度')" />
      </a-form-model-item>
      <a-form-model-item prop="dataEnums" >
        <span slot="label" >
          <a-tooltip>
            {{$t('数据枚举')}}<template slot="title">{{$t('数据枚举')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="decimals" >
        <span slot="label" >
          <a-tooltip>
            {{$t('小数位数')}}<template slot="title">{{$t('小数位数')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.decimals"  :placeholder="$t('通用.输入.请输入')+$t('小数位数')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSampleDataAttr, addSampleDataAttr, updateSampleDataAttr } from '@/api/device/sample/deviceDataAttr/sampleDataAttr'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag/index.vue";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        sampleId: null,

        regAddr: null,

        regAddrBit: null,

        attributeName: null,

        attributeIdentifier: null,

        attributeType: null,

        dataType: null,

        dataTemplate: null,

        remark: null,

        createTime: null,

        sort: null,

        isDelete: null,

        projectId: null,

        attributeUnit: null,

        icon: null,

        threshold: null,

        store: null,

        storeTimer: null,

        storeRange: null,

        dataEnums: null,

        decimals: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        sort: [
          { required: true, message: this.$t('排序')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        sampleId: null,
        regAddr: null,
        regAddrBit: null,
        attributeName: null,
        attributeIdentifier: null,
        attributeType: null,
        dataType: null,
        dataTemplate: null,
        remark: null,
        createTime: null,
        sort: null,
        isDelete: null,
        projectId: null,
        attributeUnit: null,
        icon: null,
        threshold: null,
        store: null,
        storeTimer: null,
        storeRange: null,
        dataEnums: null,
        decimals: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSampleDataAttr({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSampleDataAttr(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSampleDataAttr(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
